@import "../../App.scss";


.landing {
    display: flex;
    flex-direction: column;
    background-color: #F1F6FE;
    width: 100vw;

    .landing-container {
        display: flex;
        flex-direction: column;
        padding-bottom: 60px;
        width: 100%;

        .landing-section-1-bg{
            width: 100%;
            height: auto;
            background: url(../../assets/images/bg-header.jpg);
            background-size: contain;
            display: flex;
            flex-direction: column;
            background-repeat: repeat;
        }
      

        .landing-section-1 {
            display: flex;
            margin-top: 5%;
            margin-left: auto;
            margin-right: auto;
            max-width: 761px;
            width: 100%;
        
            flex-direction: column;

            .landing-section-1-header {
                font-size: 48px;
                font-family: $fontMedium;
                color: $text-color;
                text-align: center;
                @include media-breakpoint-down(md){
                    margin-left: auto;
                    margin-right: auto;
                    font-size: 28px;
                }
            }

            .landing-section-1-header-special {
                background: $gradient-primary;
                background-clip: text;
                -webkit-background-clip: text;
                color: transparent;
                position: relative;
                padding: 4px;
                font-weight: bold;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 14%;
                    left: 0;
                    right: 0;
                    height: 4px;
                    background: $gradient-primary;
                    transform: rotate(-1.572deg);
                    z-index: 0;
                }
            }

            .landing-section-1-sub-header {
                margin-top: 18px;
                font-size: 18px;
                font-family: $fontRegular;
                color: $text-gray-color;
                text-align: center;

                @include media-breakpoint-down(md){
                    margin-left: auto;
                    margin-right: auto;
                    font-size: 16px;
                }
            }

            

            .landing-section-1-button {
                width: 300px;
                height: 46px;
                margin-left: auto;
                margin-right: auto;
                border-radius: 8px;
                margin-top: 36px;
                background-color: $button-primary-color;
                border: none;
                color: white;
                box-shadow: 0px 8px 18px 0px rgba(61, 70, 85, 0.30);
            }
        }

        .landing-section-2 {
            display: flex;
            margin-top: 10%;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            padding-top: 90px;
            padding-bottom: 90px;
            flex-direction: column;
            background-color: white;

            .landing-section-2-control{
                width: 100%;
                padding-left: 5%;
                padding-right: 5%;
                display: flex;
                flex-direction: column;
            }

            .landing-section-2-header-1 {
                font-size: 26px;
                font-family: $fontMedium;
                color: $text-color;
                text-align: center;
                margin-bottom: 66px;
                @include media-breakpoint-down(md){
                    font-size: 22px;
                }
            }

          

            .landing-section-2-sub-header {
                font-size: 18px;
                font-family: $fontRegular;
                color: $text-gray-color;
                width: 60%;
                margin-top: 16px;
                margin-left: auto;
                margin-right: auto;
                text-align: center;

                @include media-breakpoint-down(md) {
                    width: 90%;
                }
            }

            .landing-section-2-grid{
                display: grid;
                grid-template-columns: 50% 50%;
               

                @include media-breakpoint-down(md){
                    grid-template-columns: 100%;
                }
                .landing-section-2-item{
                    display: flex;
                    flex-direction: column;
                    width: 95%;
                    margin-bottom: 66px;

                    @include media-breakpoint-down(md){
                        margin-left: auto;
                        margin-right: auto;
                        width: 100%;
                    }
    
                    .landing-section-2-item-img{
                        width: 51px;
                        height: 51px;
                        object-fit: contain;
                        margin-bottom: 12px;
                        @include media-breakpoint-down(md){
                            margin-left: auto;
                            margin-right: auto;
                        }
                    }
    
                    .landing-section-2-item-text-small{
                        font-size: 26px;
                        font-family: $fontMedium;
                        color: $text-gray-color;

                        @include media-breakpoint-down(md){
                            margin-left: auto;
                            margin-right: auto;
                            @include media-breakpoint-down(md){
                                font-size: 26px;
                            }
                        }
                    }
                    .landing-section-2-item-text-title{
                        font-size: 36px;
                        font-family: $fontMedium;
                        color: $text-color;
                        @include media-breakpoint-down(md){
                            margin-left: auto;
                            margin-right: auto;
                            text-align: center;
                            @include media-breakpoint-down(md){
                                font-size: 30px;
                            }
                        }
                        
                    }
                    .landing-section-2-item-text-title-special{
                    
                        background: $gradient-primary;
                        background-clip: text;
                        -webkit-background-clip: text;
                        color: transparent;
                        position: relative;
                    }
                    .landing-section-2-item-text-desc{
                        font-size: 16px;
                        font-family: $fontRegular;
                        color: $text-gray-color;
                        width: 80%;

                        @include media-breakpoint-down(md){
                            margin-left: auto;
                            margin-right: auto;
                            width: 100%;
                            text-align: center;
                            font-size: 14px;
                        }
                    }
                }
            }

            .landing-section-2-center{
                margin-top: 36px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .landing-section-2-item{
                    display: flex;
                    flex-direction: column;
                    width: 95%;
                   
    
                    .landing-section-2-item-img{
                        width: 51px;
                        height: 51px;
                        object-fit: contain;
                        margin-left: auto;
                        margin-right: auto;
                    }
    
                    .landing-section-2-item-text-small{
                        font-size: 22px;
                        font-family: $fontMedium;
                        color: $text-gray-color;
                        margin-left: auto;
                        margin-right: auto;
                        text-align: center;
                    }
                    .landing-section-2-item-text-title{
                        font-size: 36px;
                        font-family: $fontMedium;
                        color: $text-color;
                        margin-left: auto;
                        margin-right: auto;
                        text-align: center;
                    }
                    .landing-section-2-item-text-title-special{
                    
                        background: $gradient-primary;
                        background-clip: text;
                        -webkit-background-clip: text;
                        color: transparent;
                        position: relative;
                    }
                    .landing-section-2-item-text-desc{
                        font-size: 16px;
                        font-family: $fontRegular;
                        color: $text-gray-color;
                        margin-left: auto;
                        margin-right: auto;
                        width: 80%;
                        text-align: center;
                    }
                }
            }

            
        }
        .landing-section-3 {
            display: flex;
            margin-top: 5%;
            margin-left: auto;
            margin-right: auto;
            max-width: 761px;
            width: 100%;
            flex-direction: column;

            @include media-breakpoint-down(md){
                margin-left: auto;
                margin-right: auto;
                padding-top: 66px;
            }


            .landing-section-3-header {
                font-size: 22px;
                font-family: $fontMedium;
                color: $text-color;
                text-align: center;
            }

            .landing-section-3-header-special {
                background: $gradient-primary;
                background-clip: text;
                -webkit-background-clip: text;
                color: transparent;
                position: relative;
                padding: 4px;
                font-weight: bold;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 14%;
                    left: 0;
                    right: 0;
                    height: 4px;
                    background: $gradient-primary;
                    transform: rotate(-1.572deg);
                    z-index: -1;
                }
            }

            .landing-section-3-sub-header {
                margin-top: 18px;
                font-size: 18px;
                font-family: $fontRegular;
                color: $text-gray-color;
                text-align: center;
            }

            

            .landing-section-3-button {
                width: 300px;
                height: 46px;
                margin-left: auto;
                margin-right: auto;
                border-radius: 8px;
                margin-top: 18px;
                margin-bottom: 18px;
                background-color: $button-primary-color;
                border: none;
                color: white;
                box-shadow: 0px 8px 18px 0px rgba(61, 70, 85, 0.30);
            }
        }


        
    }
}