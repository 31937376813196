@import "../node_modules/bootstrap/scss/bootstrap.scss";
$fontBold: "LINESeedSansTH_He";
$fontRegular: "LINESeedSansTH_Rg";
$fontMedium: "LINESeedSansTH_Bd";
$fontLight: "LINESeedSansTH_Th";

$primary-color:  #4008DC;
$text-blue-color: #4008DC;
$secondary-color: #AF34F4;
$button-primary-color: #4008DC;
$text-color: #3b3b3b;
$text-gray-color: #6e6e6e;
$text-white-color: #ffffff;
$disabled-color: #b3b3b3;
$blue-line: #B4DEFA;
$header-color: #333333;
$guest-bg: #797979;
$bg-color: #ffffff;
$border-line: #3b3b3b;
$link-color: #3d7ab4;
$border-input: #888888;
$gradient-primary: linear-gradient(85deg, #AF34F4 0%, #7321E4 39.97%, #4008DC 100%);

a{
    cursor: pointer;
    color: #64b5fc;
    margin-top: 16px;
    font-family: $fontMedium;
    font-size: 14px;
    text-decoration: underline;
}
body{
    font-family: $fontRegular !important;
    background-color: $bg-color;
    scrollbar-width: none;
    
}

.MuiInputBase-input {
    font-family: $fontRegular !important;
}
.MuiFormLabel-root{
    font-family: $fontRegular !important;
}
.MuiChip-label{
    font-family: $fontMedium !important;
}
::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

.searching-load{
    width: 200px;
    height: 200px;
    object-fit: contain;
}

.bg-light{
    background-color: $header-color !important;
}

.searching-text{
    font-size: 16px;
    font-family: $fontMedium;
    margin-top: 4px;
}
.button-default-blue{
    background-color: $primary-color;
    color: $text-white-color;
    height: 46px;
    font-family: $fontMedium;
    font-size: 16px;
    min-width: 100px;
    border: none;

    &:hover{
        background-color: $primary-color;
    }
}
.button-default{
    background-color: $secondary-color;
    color: $text-color;
    height: 46px;
    font-family: $fontMedium;
    font-size: 16px;
    min-width: 100px;
    border: $border-line 2px solid;
    border-radius: 8px;

    &:hover{
        background-color: $primary-color;
    }
}

.link-default{
    font-size: 16px;
    font-family: $fontRegular;
    color: $button-primary-color;
    text-decoration: underline;
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
}
p{
    margin-bottom: 0px !important;
}

@font-face {
    font-family: $fontBold;
    src: local("LINESeedSansTH_He"), url(../public/fonts/LINESeedSansTH_He.ttf) format("truetype");
}

@font-face {
    font-family: $fontMedium;
    src: local("LINESeedSansTH_Bd"), url(../public/fonts/LINESeedSansTH_Bd.ttf) format("truetype");
}
@font-face {
    font-family: $fontRegular;
    src: local("LINESeedSansTH_Rg"), url(../public/fonts/LINESeedSansTH_Rg.ttf) format("truetype");
}
@font-face {
    font-family: $fontLight;
    src: local("LINESeedSansTH_Th"), url(../public/fonts/LINESeedSansTH_Th.ttf) format("truetype");
}
