@import "../../App.scss";

.footer-control{
    width: 100vw;
    min-height: 80px;
    background-color: #3D4655;
    display: flex;
    @include media-breakpoint-down(md){
        padding: 36px 24px;
    }

    
    .footer-container{
        display: flex;
        @include media-breakpoint-down(md){
            flex-direction: column;
        }
    
    
    }

    .footer-brand{
        font-size: 20px;
        color: white;
        font-family: $fontRegular;
        margin-top: auto;
        margin-bottom: auto;

        @include media-breakpoint-down(md){
            margin-left: auto;
            margin-right: auto;
            font-size: 24px;
            margin-bottom: 16px;
        }
    
    
    }

    .footer-term{
        font-size: 17px;
        color: white;
        margin-left: auto;
        font-family: $fontRegular;
        margin-top: auto;
        margin-bottom: auto;
        @include media-breakpoint-down(md){
            margin-left: auto;
            margin-right: auto;
            font-size: 12px;
        }
    }

    .footer-term-a{
        cursor: pointer;
        
        font-family: $fontMedium;

        &:hover{
            text-decoration: underline;
        }
    }

}