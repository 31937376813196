@import "../../../App.scss";

.template-0-control{
    margin-top: 5%;
    display: grid;
    grid-template-columns: 100%;

    .template-0-cms-background{
        
        background-color: white;
    }


    .template-0-form-control{
        width: 100%;
        box-shadow: 0px 0px 10px $border-input;
        display: grid;
        min-height: 800px;
        grid-template-columns: 30% 70%;


        .template-0-left-control{
            width: 100%;
            display: flex;
            flex-direction: column;
            padding-top: 16px;
            padding-bottom: 34px;
            padding-left: 0px;
            padding-right: 0px;
           
            .template-0-profile-control{
                display: flex;
                margin-left: auto;
                margin-right: auto;
                margin-top: 36px;
                margin-bottom: 68px;
                .template-0-profile{
                    width: 200px;
                    height: 200px;
                    margin: auto;
                    background-size: cover;
                    border-radius: 50%;
                    background-position: center center;
                    background-repeat: no-repeat;
                }

                .template-0-profile-button{
                    width: 150px;
                    height: 46px;
                    color: white;
                    background-color: $primary-color;
                }
            }

            .template-0-item-control{
                    padding-left: 16px;
                    margin-top: 16px;
            }

            .template-0-title-left{
                font-size: 20px;
                color: white;
                font-family: $fontMedium;
                word-wrap: break-word;
            }

            .template-0-divider{
                width: 100%;
                height: 3px;
                background-color: white;
            }

            
           
        }
      
        .template-0-content-control{
            display: flex;
            margin-top: 12px;
        }
        .template-0-content-black{
            display: flex;
            flex-direction: column;
            margin-top: 4px;
            font-size: 13px;
            margin-bottom: 4px;
            margin-left: 12px;
            margin-right: 36px;
            color: $text-color;
            word-wrap: break-word;
        }
        .template-0-content{
            display: flex;
            flex-direction: column;
            margin-top: 4px;
            font-size: 13px;
            margin-bottom: 4px;
            margin-left: 12px;
            margin-right: 24px;
            word-wrap: break-word;
            color: white !important;
        }
        .template-0-right-control{
            width: 100%;
            display: flex;
            flex-direction: column;
            background-color: white;
            padding-top: 16px;
            padding-bottom: 34px;
            padding-left: 24px;
            padding-right: 0px;

            .template-0-exp-control{
                margin-top: 48px;
                display: flex;
                flex-direction: column;

                .template-0-exp-title{
                    font-size: 26px;
                    color: $text-color;
                    word-wrap: break-word;
                    font-family: $fontMedium;
                }

                .template-0-exp-divider{
                    width: 100%;
                    height: 3px;
                    background-color: $border-input;
                }
            }


            .template-0-name-control{

                .template-0-name{
                    font-size: 36px;
                    font-family: $fontMedium;
                    color: $text-color;
                    word-wrap: break-word;
                }

                .template-0-position{
                    font-size: 22px;
                    font-family: $fontRegular;
                    color: $text-color;
                    margin-right: 36px;
                    word-wrap: break-word;
                }
                .template-0-cover-letter{
                    font-size: 13px;
                    font-family: $fontRegular;
                    color: $text-color;
                    margin-right: 36px;
                    word-wrap: break-word;
                }
            }

        }
    }
}