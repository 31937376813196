@import "../../App.scss";

.header {
    width: 100vw;
    height: 80px;
    background-color: transparent;
    position: sticky;
    display: flex;

    .header-navbar-side{
        height: 100vh;
        background-color: #F1F6FE;
        width: 100vw;
        z-index: 2;
        padding: 36px;
    }


    .header-grid-control {
        width: 100%;
        margin-left: auto;
        padding-top: 8px;
        padding-bottom: 8px;
        margin-right: auto;
        padding-left: 0px;
        padding-right: 0px;
        display: grid !important;
        grid-template-columns: 50% 50% !important;

        @include media-breakpoint-down(lg) {
            grid-template-columns: 50% 50% !important;
            padding-left: 2%;
            padding-right: 2%;
        }

        .header-logo-control {
            display: flex;
            .header-branding {
                cursor: pointer;
                background: $gradient-primary;
                background-clip: text;
                -webkit-background-clip: text;
                color: transparent;
                position: relative;
                padding: 4px;
                font-size: 18px;
                font-weight: bold;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0%;
                    left: 0;
                    right: 0;
                    height: 4px;
                    background: $gradient-primary;
                    transform: rotate(-1.572deg);
                }
            }
            .header-logo-img {
                width: 200px;
                height: auto;
                object-fit: contain;
                margin-top: auto;
                margin-bottom: auto;

                @include media-breakpoint-down(md) {
                    height: 41px;
                    object-fit: contain;
                    width: auto;
                }
            }

        }
        .nav-profile{
            background-color: $button-primary-color;
            border-radius: 18px;
            margin-top: auto;
            margin-bottom: auto;
            cursor: pointer;
            padding: 6px 8px;
            display: flex;

            @include media-breakpoint-down(md){
                flex-direction: column;
            }

            .nav-link{
                padding: 0px !important;
                display: flex;
                margin-top: 0px;

               
            }
            .dropdown-toggle::after{
                margin-top: auto !important;
                margin-bottom: auto !important;
            }
            .dropdown-menu{
                padding: 0px !important;
            }
            .nav-profile-img{
                width: 30px;
                height: 30px;
                border-radius: 50%;
                margin-top: auto;
                margin-bottom: auto;
            }

            .nav-dropdown-custom{
                padding: 6px 24px;
                font-size: 13px;
                font-family: $fontMedium;
                border: none !important;
            }

            .nav-name{
                margin-left: 12px;
                font-size: 12px;
                color: white;
                margin-top: auto;
                margin-bottom: auto;
                font-family: $fontMedium;
            }
        }


        .header-menu-center-control {

            display: flex;
            margin-left: auto;
            margin-top: auto;
            margin-bottom: auto;

            @include media-breakpoint-down(lg) {
                display: none;
            }

        }

        .header-menu-a {
            margin-left: 12px;
            margin-right: 12px;
            margin-top: auto;
            margin-bottom: auto;
            font-size: 16px;
            color: $text-color;
            font-family: $fontRegular;

            &:hover {
                font-family: 13px;
                font-family: $fontMedium;
                color: $text-blue-color;
            }
        }

        .header-menu-a-active {
            margin-left: 12px;
            margin-right: 12px;
            font-size: 16px;
            color: $text-blue-color;
            font-family: $fontMedium;
            text-decoration: underline;

            &:hover {
                color: $text-blue-color;
                text-decoration: underline;
            }
        }




        .header-menu-nav-mobile-control {
            display: none;

            @include media-breakpoint-down(lg) {
                display: block;
                margin-left: auto;
            }
        }


    }



    .header-nav-link-control {
        margin-left: auto;
    }

}