@import "../../App.scss";


.choose-template{
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-height: 100vh;
    margin-bottom: 60px;


    .choose-template-text{
        font-size: 36px;
        text-align: center;
        font-family: $fontMedium;
        color: $text-color;
        margin-bottom: 36px;
    }

    .choose-template-control{
       
        margin: auto;
        display: flex;
        width: 100%;
        flex-direction: column;

        @include media-breakpoint-down(md){
            width: 100%;
        }
        .choose-template-slide-control{
            width: 500px;
            display: flex;
            margin-left: auto;
            margin-right: auto;

            @include media-breakpoint-down(md){
                width: 300px;
            }
            .choose-template-slide{
                width: 400px;
                height: 606px;
                object-fit: contain;
                margin-left: auto;
                margin-right: auto;

                @include media-breakpoint-down(md){
                    width: 300px;
                    height: 506px;
                 
                }
            }

            .choose-template-slide-box-shadow{
                border: 5px solid $primary-color;
                box-shadow: 0px 0px 10px $primary-color;
            }
        }

       
    }

    .choose-template-arrow-control{
        width: 90%;
        display: flex;
    }
    .choose-template-arrow{
        width: 50px;
        height: 50px;
        object-fit: contain;
        margin-top: auto;
        margin-bottom: auto;

        @include media-breakpoint-down(md){
            width: 30px;
            height: 30px;
        }
    }
    .choose-template-button{
        width: 300px;
        height: 51px;
        margin-top: 36px;
        background-color: $button-primary-color;
        border-radius: 8px;
        border: none;
        font-family: $fontMedium;
        font-size: 18px;
        margin-left: auto;
        margin-right: auto;
        box-shadow: 0px 8px 18px 0px rgba(61, 70, 85, 0.30);
    }
}