@import "../../App.scss";

.view-resume-control{
    max-width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;

    .page-container {
        width: 21cm; // 793.7007874
        height: 29.7cm; // 1122.519685
        margin: 2cm auto;
        margin-top: -2%;
        /* Creates a 2cm margin on all sides */
        background-color: white;
        /* Optional: Add a box shadow for visual effect */
    }
}



.view-builder-control{
    display: flex;
    margin-bottom: 50px;
    margin-top: 36px;
    flex-direction: column;

    .view-builder-download{
        width: 250px;
        height: 46px;
        margin-left: auto;
        margin-right: auto;
        background-color: $button-primary-color;
        border-radius: 8px;
        border: none;
        &:hover{
            background-color: $button-primary-color;
        }
    }

    .view-builder-downloading-control{
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        .view-builder-downloading-progress{
            margin-left: auto;
            margin-right: auto;
        }

        .view-builder-downloading-text{
            font-size: 16px;
            color: $text-gray-color;
            font-family: $fontRegular;
            margin-left: auto;
            margin-right: auto;
        }

    }

    .view-builder-text-consent{
        margin-top: 16px;
        font-size: 12px;
        text-align: center;
        color: $text-gray-color;
        font-family: $fontRegular;
    }

    .view-builder-text-consent-a{
       color: $link-color;
       text-decoration: underline;
       cursor: pointer;
    }
    
}
.view-builder-text-control{
    margin-left: auto;
    margin-right: auto;
    margin-top: 12px;
    margin-bottom: 48px;
    display: flex;
    flex-direction: column;
}
.view-builder-text{
    font-size: 16px;
    font-family: $fontMedium;
    color: $text-color;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.view-builder-text-a{
    font-size: 16px;
    font-family: $fontMedium;
    color: #3da8ff;
    text-decoration: underline;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
}

