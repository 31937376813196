@import "../../../App.scss";


.template-1-control {
    display: block;
    max-width: 100%;
    word-wrap: break-word;

    .template-1-cms-background {

        background-color: white;
    }

    .template-1-form-control {
        display: flex;
        flex-direction: column;
        .template-1-footer-control{
            height: 50px;
            width: 100%;
        }
        .template-1-desc-control {
            display: grid;
            grid-template-columns: 30% 70%;
            padding: 24px;
            background-color: white;

            .template-1-desc-right-control {
                display: flex;
                flex-direction: column;
                padding: 4px 24px 4px 4px;
                .template-1-desc-right-section-control {
                    display: flex;
                    flex-direction: column;
                    margin-top: 16px;
                    margin-bottom: 16px;
                    padding-left: 24px;

                    .template-1-desc-title {
                        font-size: 24px;
                        color: $text-color;
                        font-family: $fontMedium;
                        word-wrap: break-word;
                    }

                    .template-1-desc-content {
                        font-size: 14px;
                        color: $text-color;
                        margin-top: 16px;
                        word-wrap: break-word;
                        font-family: $fontRegular;
                    }
                }
            }

            .template-1-desc-left-control {
                display: flex;
                flex-direction: column;
                padding: 4px 24px 4px 4px;



                .template-1-desc-left-section-control {
                    display: flex;
                    flex-direction: column;
                    margin-top: 16px;
                    margin-bottom: 16px;


                    .template-1-desc-title {
                        font-size: 24px;
                        color: $text-color;
                        font-family: $fontMedium;
                        word-wrap: break-word;
                    }

                    .template-1-desc-divider {
                        width: 100%;
                        height: 3px;
                        background-color: $border-input;
                        margin-bottom: 16px;
                    }

                    .template-1-desc-content {
                        font-size: 16px;
                        color: $text-color;
                        word-wrap: break-word;
                        font-family: $fontRegular;
                    }
                }
            }

        }

        .template-1-profile-control {
            display: grid;
            flex-direction: column;
            min-height: 150px;
            background-color: black;
            grid-template-columns: 60% 40%;
            padding: 24px;

            .template-1-profile-text-control {
                display: flex;
                flex-direction: column;
            }

            .template-1-name {
                font-size: 36px;
                font-family: $fontMedium;
                color: white;
                word-wrap: break-word;
            }

            .template-1-position {
                font-size: 22px;
                font-family: $fontRegular;
                color: white;
                margin-right: 36px;
                word-wrap: break-word;
            }

            .template-1-cover-letter {
                font-size: 13px;
                font-family: $fontRegular;
                color: white;
                margin-right: 36px;
                word-wrap: break-word;
            }

            .template-1-cover-contact-control {
                display: flex;
                flex-direction: column;
            }

            .template-1-cover-contact {
                font-size: 15px;
                font-family: $fontRegular;
                color: white;
                margin-right: 36px;
                text-align: right;
                margin-top: auto;
                margin-bottom: auto;
                word-wrap: break-word;
            }
        }



    }
}