@import "../../App.scss";


.builder {
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: rgb(243, 243, 243);
}

.builder-control {
    margin-top: 5%;
    display: grid;
    grid-template-columns: 100%;
   

    .builder-cms-background {
        height: auto;
        display: block;
        background-color: white;
        margin-top: 4px;
        width: 100%;
    }

 
    .ql-toolbar.ql-snow + .ql-container.ql-snow{
        min-height: 200px;
    }


    .builder-form-control {
        width: 100%;
        border-radius: 8px;
        box-shadow: 0px 0px 10px $border-input;
        display: grid;
        height: auto;
        background-color: #7321E4;
        padding: 12px;
        grid-template-columns: 40% 60%;

        @include media-breakpoint-down(md) {
            grid-template-columns: 100%;
        }
        .builder-form-item-control-right{
            display: block;
            width: 100%;
            max-width: 100%;
            word-wrap: break-word;

            @include media-breakpoint-down(md){
                display: none;
            }
        }

        .builder-form-item-control {
            display: flex;
            flex-direction: column;

            .builder-form-item-section {
                display: flex;
                flex-direction: column;
                margin-top: 4px;
                margin-bottom: 4px;
                border-radius: 4px;
                padding: 12px;
                width: 95%;
                height: auto;
                background-color: white;

                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }

            .builder-form-item-section-error {
               border: 4px solid red;
            }

            .builder-form-item-title-control{
                display: flex;
            }

            .builder-form-item-title {
                font-size: 13px;
                color: $text-color;
                font-family: $fontMedium;
            }

            .builder-form-ai-control{
                display: flex;
                margin-left: auto;
                cursor: pointer;

                .builder-form-ai-img{
                    width: 20px;
                    height: 20px;
                    margin-top: auto;
                    margin-bottom: auto;
                    object-fit: contain;
                }
                .builder-form-ai-text{
                  font-size: 13px;
                  font-family: $fontMedium;
                  color: $button-primary-color;
                  margin-left: 6px;
                  margin-top: auto;

                  &:hover{
                    font-family: $fontMedium;
                  }
                }
            }

            .builder-form-item-contact-title{
                font-size: 12px;
                color: $text-color;
                font-family: $fontMedium;
            }

            .builder-form-item-contact-input{
                font-size: 14px;
                color: $text-color;
                font-family: $fontMedium;
            }
            .builder-form-item-desc {
                font-size: 12px;
                word-wrap: break-word;
                color: $text-color;
                font-family: $fontRegular;
            }

            .builder-form-item-profile {
                width: 150px;
                height: 150px;
                border-radius: 50%;
                object-fit: cover;
                margin-top: 16px;
            }

            .builder-form-item-button {
                cursor: pointer;
                margin-top: auto;
                margin-bottom: auto;
                color: $button-primary-color;
                font-size: 14px;
                font-family: $fontMedium;
                margin-left: auto;

                
            }
        }


    }

    .builder-color-picker {
        width: 100px;
        height: 100px;
        margin-top: 4px;
        margin-bottom: 4px;

    }

    .builder-preview-button {
        margin-top: 36px;
        width: 250px;
        height: 46px;
        margin-left: auto;
        margin-right: auto;
        background-color: $button-primary-color;
        border-radius: 16px;
        border: none;
        margin-bottom: 60px;
        &:hover{
            background-color: $button-primary-color;
        }
    }

    .builder-progress-circle{
       margin-top: auto;
       margin-bottom: auto;
    }

    .builder-required-red{
        font-family: $fontMedium;
        color: red;
    }
    .builder-required-red-gray{
        font-size: 9px;
        font-family: $fontRegular;
        color: $text-gray-color;
    }
}